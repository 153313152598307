
import React from 'react'
import { getAuth, sendPasswordResetEmail } from 'firebase/auth'

import Meta from 'layout/Meta'
import firebase from 'utils/firebase'
import useStatus from 'utils/useStatus'
import Spinner from 'utils/Spinner'


export default function SignInPage() {

	const [status, setStatus] = useStatus()
	const emailRef = React.useRef(null)

	const handleSubmit = async (event) => {
		event.preventDefault()
		setStatus(true)
		try {
			const auth = getAuth(firebase)
			await sendPasswordResetEmail(auth, `${emailRef.current.value}`.trim())
			setStatus({ success: true })
		} catch (error) { setStatus({ error }) }
	}
	
	return <main>

		<Meta title="Forgot Password" />

		<h1>Reset password</h1>

		{ !status.success && <form onSubmit={handleSubmit}>

			<label>
				Email:
				<input type='email'
					ref={emailRef}
					autoComplete='email'
				/>
			</label>

			<p>
				<button type='submit' disabled={status.working}>
					Reset password
				</button>
			</p>

			{ status.working && <Spinner>Sending reset email</Spinner> }

		</form> }

		{ status.success &&
			<p>An email with a password reset link was sent to your address.</p> }

	</main>
}